<template>
  <div>
    <b-card>
      <b-card-text>
        <div class="row">
          <div class="col-md-6">
            <b-form-group label="DNI/RUC">
              <b-form-input
                v-model="person.document_number"
                required
                maxlength="11"
              ></b-form-input>
            </b-form-group>
          </div>
          <div class="col-md-6">
            <b-form-group label="Nombre/Razón social">
              <b-form-input v-model="person.name" required></b-form-input>
            </b-form-group>
          </div>
          <div class="col-md-6">
            <b-form-group label="Primer apellido">
              <b-form-input v-model="person.last_name" required></b-form-input>
            </b-form-group>
          </div>
          <div class="col-md-6">
            <b-form-group label="Segundo apellido">
              <b-form-input
                v-model="person.last_name_2"
                required
              ></b-form-input>
            </b-form-group>
          </div>
          <div class="col-md-6">
            <b-form-group label="Email">
              <b-form-input v-model="person.email" required></b-form-input>
            </b-form-group>
          </div>
        </div>
      </b-card-text>
      <template #footer>
        <b-button variant="primary" @click="saveForm">Guardar</b-button>
      </template>
    </b-card>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";

export default {
  name: "PersonCreate",
  data() {
    return {
      person: {}
    };
  },
  mounted() {
    this.getPerson();
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Persona" },
      { title: "Crear persona" }
    ]);
  },
  methods: {
    makeToastVariant(message = "Éxito", variant = "success") {
      this.$bvToast.toast(message, {
        title: variant === "success" ? "Éxito" : "Ooops",
        variant: variant,
        solid: true
      });
    },
    getPerson() {
      if ("id" in this.$route.params) {
        ApiService.get(`person/${this.$route.params.id}`).then(res => {
          this.person = res.data;
        });
      }
    },
    saveForm() {
      let url =
        "id" in this.$route.params
          ? `person/${this.$route.params.id}/`
          : "person/";
      let method =
        "id" in this.$route.params ? ApiService.patch : ApiService.post;

      method(url, this.person)
        .then(() => {
          this.makeToastVariant("Se ha creado la persona correctamente");
          if (!("id" in this.$route.params)) {
            this.person = {};
          }
        })
        .catch(() => {
          this.$bvToast.toast("No se ha guardado la persona", {
            title: `Ooops`,
            variant: "danger",
            solid: true
          });
        });
    }
  }
};
</script>
